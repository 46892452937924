@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@300&display=swap');

* {
  margin: 0;
  padding: 0;
  font-family: 'Nunito', sans-serif;
  scroll-behavior: smooth;
  box-sizing: border-box;
}

.section {
  padding: 0 200px;
  min-height: 100vh;
}

.text-highlight {
  background: linear-gradient(89.97deg, #AE67FA 0%, #F49867 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.material-symbols-outlined {
  font-variation-settings:
  'FILL' 0,
  'wght' 400,
  'GRAD' 0,
  'opsz' 48;
  cursor: pointer;
  user-select: none;
}

.scale-up-center {
  -webkit-animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
  animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

@-webkit-keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@media screen and (max-width: 1400px) {
  .section {
    padding: 0 100px 1em;
  }
}

@media screen and (max-width: 800px) {
  .section {
    padding: 0 25px 1em;
  }
}

@media screen and (max-width: 400px) {
  .section {
    padding: 0 10px 1em;
  }
}