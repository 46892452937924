.contact {
    background: #181f27;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.contact-content {
    color: white;
    margin-top: -8rem;
    font-size: calc(10px + 2vmin);
}

.contact-content p {
    margin-top: 0.7rem;
}

.contact-icons {
    margin-top: 3rem;
    display: flex;
    justify-content: space-between;
}

.contact-icon {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
    transition: transform .5s;
}

.contact-icon p {
    margin-top: 0;
}

.contact-icon:hover {
    transform: scale(1.05);
}

.contact-icon img {
    width: calc(50px + 2vmin);
}

.contact-icons a {
    text-decoration: none;
    color: inherit;
}

@media screen and (max-width: 420px) {
    .contact-icon {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        gap: 2rem;
    }

    .contact-icons {
        flex-direction: column;
        gap: 2rem;
        margin-left: 3rem;
    }
    .contact-content {
        margin-top: 0
    }
}

@media screen and (max-height: 600px) {
    .contact-content {
        margin-top: 0
    }
}