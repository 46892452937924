.profile {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: calc(10px + 2vmin);
  }

.profile-picture {
    max-height: 66vh;
    min-height: 300px;
    max-width: 90vw;
    min-width: 225px;
    margin: 0 0 -8px;
    aspect-ratio: 0.75 / 1;
  }

.image-container {
  background: radial-gradient(#8C34C599 0%, #1113 80%);
  padding: 1rem 1rem 0;
  border-radius: 300px 300px 70px 70px;
}

@media screen and (max-height: 600px) {
    .profile-picture {
      max-height: 62vh;
      max-width: 62vw;
      margin-top: 0;
    }
  }
@media screen and (max-width: 420px) {
  .image-container {
    margin-top: 2rem;
  }
}